<template>
  <div class="sup_content sup_user_profile">
        <h2>Profile</h2>
        <div class="sup_inner_content">
            <div class="sup_profile_icon">
                <figure>
                    <img :src="imageUrl + '/avatar.png'" alt="">
                </figure>
                <Button icon="pi pi-pencil" class="p-button-rounded p-button-primary p-button-sm" v-tooltip.bottom="'Edit Profile Icon'" />
            </div>
        </div>
  </div>
</template>

<script>
import { imageUrl } from '../../../../config/constant';

export default {
    name: 'Profile',
    setup(){
        return{
            imageUrl
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/style/component/superAdmin/pages/myAccount/Profile.scss";
</style>